// React core and custom hooks
import { memo, useState } from "react";
import { useGetProjectsQuery } from "./projectsApiSlice";

// Chakra Components and Icons
import { Tr, Td, Button, Modal, Link, ModalContent, ModalHeader, ModalBody, ModalFooter, Text, HStack, VStack, Table, Thead, Th, Tbody, Tooltip, Image } from "@chakra-ui/react";
import { MdEdit, MdDelete } from "react-icons/md";
import StandardTdComponent from "../../components/StandardTdComponent";
import { DeleteProject, UpdateProject } from "./common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Project = ({ count, projectId }) => {
  const [isEnter, setIsEnter] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [openModalDetailProject, setOpenModalDetailProject] = useState(false);
  const { project } = useGetProjectsQuery("projectsList", {
    selectFromResult: ({ data }) => ({
      project: data?.entities[projectId],
    }),
  });

  if (project) {
    const {
      project: projectName,
      location,
      type,
      manager,
      kasi,
      pic,
      email,
      ksoImage,
    } = project;
    const modalDetailProject = () => (
      <Modal
        isOpen={openModalDetailProject}
        onClose={() => setOpenModalDetailProject(false)}
      >
        <ModalContent>
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text>Project Detail</Text>
            <VStack
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faClose} onClick={() => setOpenModalDetailProject(false)} />
            </VStack>
          </ModalHeader>
          <ModalBody>
          <Table variant="ghost">
            <Tbody>
              <Tr>
                <Td>Project Name</Td>
                <Td>{projectName}</Td>
              </Tr>
              <Tr>
                <Td>Project Location</Td>
                <Td>{location}</Td>
              </Tr>
              <Tr>
                <Td>Project Type</Td>
                <Td>{type}</Td>
              </Tr>
              <Tr>
                <Td>Project Manager</Td>
                <Td>{manager}</Td>
              </Tr>
              <Tr>
                <Td>Kasi Engineering</Td>
                <Td>{kasi}</Td>
              </Tr>
              {pic.map((item, index) => (
                <Tr>
                  <Td>Contact PIC Project ({index + 1})</Td>
                  <Td>{item.name} - {item.phone}</Td>
                </Tr>
              ))}
              <Tr>
                <Td>Email</Td>
                <Td>{email}</Td>
              </Tr>
              <Tr>
                <Td>Logo Laporan</Td>
                <Td>
                  <Image src={ksoImage} alt="kso-image" />
                </Td>
              </Tr>
            </Tbody>
          </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    );

    return (
      <>
        <Tr
          onMouseEnter={() => {
            setIsEnter(true);
          }}
          onMouseLeave={() => {
            setIsEnter(false);
          }}
          backgroundColor={isEnter && "rgb(240,240,240)"}
        >
          <StandardTdComponent>{count}</StandardTdComponent>
          <StandardTdComponent textAlign={"left"}>
            <Link
              onClick={() => setOpenModalDetailProject(true)}
              color="blue.500"
              _hover={{ textDecoration: "underline", color: "blue.700"}}
            >
              {projectName}
            </Link>
          </StandardTdComponent>
          <StandardTdComponent textAlign={"left"}>{location}</StandardTdComponent>
          <StandardTdComponent>{type}</StandardTdComponent>
          <StandardTdComponent>{manager}</StandardTdComponent>
          <StandardTdComponent>{pic[0]?.name ?? ""}</StandardTdComponent>
          <StandardTdComponent>
            <Tooltip label={pic[0]?.phone ?? ""} aria-label="A tooltip">
              {pic[0]?.phone ?? ""}
            </Tooltip>
          </StandardTdComponent>
          <Td>
            <Button
              leftIcon={<MdEdit />}
              colorScheme="blue"
              size={"sm"}
              right={"8px"}
              onClick={() => {
                setIsUpdate(true);
              }}
            >
              Update
            </Button>
            <Button
              leftIcon={<MdDelete />}
              colorScheme="red"
              size={"sm"}
              onClick={() => {
                setIsDelete(true);
              }}
            >
              Delete
            </Button>
          </Td>
        </Tr>
        <UpdateProject
          data={project}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
        />
        <DeleteProject
          projectName={projectName}
          id={projectId}
          isDelete={isDelete}
          setIsDelete={setIsDelete}  
        />
        {modalDetailProject()}
      </>
    );
  }
};

const memoizedProject = memo(Project);

export default memoizedProject;

import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./features/auth/Login";
import DashLayout from "./components/DashLayout";
import Welcome from "./features/auth/Welcome";
import TasksList from "./features/tasks/TasksList";
import UsersList from "./features/users/UsersList";
import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import NotFound from "./components/NotFound";
import { ROLES } from "./config/roles";
import useTitle from "./hooks/useTitle";
import ProjectsList from "./features/projects/ProjectsList";
import ReportList from "./features/reports/ReportLists";
import DetailTask from "./features/tasks/DetailTask";
import DetailReport from "./features/reports/DetailReport";
import VerifiedScreen from "./features/verified/VerifiedScreens";
import TasksKoorList from "./features/tasksKoor/TasksKoorList";
import DetailTaskKoor from "./features/tasksKoor/DetailTaskKoor";
import useAuthStatus from "./hooks/useAuthStatus";
// import UtilsList from "./features/utils/UtilsList";
// import UtilsNew from "./features/utils/UtilsNew";

function App() {
  useTitle("Wika Engineering Report System");
  const isAuthenticated = useAuthStatus();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
      <Route
          index
          element={isAuthenticated ? <Navigate to="/dash" /> : <Login />}
        />
        <Route path="login" index element={<Login />} />
        <Route path="*" element={<NotFound />} />
        <Route path="verify/:id" element={<VerifiedScreen />} />

        <Route element={<PersistLogin />}>
          <Route
            element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
          >
            <Route element={<Prefetch />}>
              <Route element={<DashLayout />}>
                <Route path="dash" index element={<Welcome />} />

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        ROLES.Manager,
                        ROLES.Admin,
                        ROLES.Koordinator,
                        ROLES.PICProject,
                      ]}
                    />
                  }
                >
                  <Route path="users">
                    <Route index element={<UsersList />} />
                  </Route>

                  <Route path="projects">
                    <Route index element={<ProjectsList />} />
                  </Route>
                </Route>

                <Route path="reports">
                  <Route index element={<ReportList />} />
                  <Route path=":id" element={<DetailReport />} />
                </Route>

                <Route path="tasks">
                  <Route index element={<TasksList />} />
                  <Route path=":id" element={<DetailTask />} />
                </Route>

                <Route path="koor-assignment">
                  <Route index element={<TasksKoorList />} />
                  <Route path=":id" element={<DetailTaskKoor />} />
                </Route>

                {/* <Route path="utils">
                  <Route index element={<UtilsList />} />
                  <Route path="new" element={<UtilsNew />} />
                </Route> */}
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;

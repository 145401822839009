// React core and custom hooks
import { useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "./usersApiSlice";

// Chakra Components and Icons
import { Tr, Td, Center, Button } from "@chakra-ui/react";
import { MdEdit, MdDelete } from "react-icons/md";
import StandardTdComponent from "../../components/StandardTdComponent";
import { DeleteUser, UpdateUser } from "./common";
import useAuth from "../../hooks/useAuth";

const User = ({ count, userId }) => {
  const [isEnter, setIsEnter] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const { username: userLogin } = useAuth();

  const { user } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      user: data?.entities[userId],
    }),
  });

  const navigate = useNavigate();

  if (user) {
    const {
      username,
      email,
      phone,
      roles: [userRole],
      name,
    } = user;

    return (
      <>
        <Tr
          onMouseEnter={() => {
            setIsEnter(true);
          }}
          onMouseLeave={() => {
            setIsEnter(false);
          }}
          backgroundColor={isEnter && "rgb(240,240,240)"}
        >
          <StandardTdComponent>{count}</StandardTdComponent>
          <StandardTdComponent textAlign="left">{name}</StandardTdComponent>
          <StandardTdComponent>{email}</StandardTdComponent>
          <StandardTdComponent>{userRole}</StandardTdComponent>
          <StandardTdComponent>{phone}</StandardTdComponent>
          <Td>
              <Button
                leftIcon={<MdEdit />}
                colorScheme="blue"
                onClick={() => {
                  setIsUpdate(true);
                }}
                size={"sm"}
                right={"8px"}
              >
                Update
              </Button>
              <Button
                leftIcon={<MdDelete />}
                colorScheme="red"
                size={"sm"}
                onClick={() => {
                  if (username !== userLogin) {
                    setIsDelete(true);
                  }
                }}
                disabled={username === userLogin}
                opacity={username === userLogin ? 0.5 : 1}
              >
                Delete
              </Button>
          </Td>
        </Tr>
        <UpdateUser
          data={user}
          id={userId}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
        />
        <DeleteUser
          userName={username}
          id={userId}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
        />
      </>
    );
  } else return null;
};

const memoizedUser = memo(User);

export default memoizedUser;

// React core and custom hooks
import useAuth from "../../hooks/useAuth"
import { useLocation } from 'react-router-dom'

// Chakra Components and Icons
import { Stack, Text, Flex, Image, Box } from "@chakra-ui/react"
import { MdOutlineDashboard, MdOutlineNotes, MdOutlineSpeakerNotes } from "react-icons/md"
// import { TbAbacus } from "react-icons/tb"
import { GoProject } from "react-icons/go"
import { HiOutlineDocumentReport } from "react-icons/hi"
import { BiUser } from "react-icons/bi"

// Proprietary Components
import DashSidebarLink from "./DashSidebarLink"

// Logos
const LogoWika = require('../../img/wika-white-no-background.png')

const DashSidebar = () => {

    const {
        isManager,
        isAdmin,
        isKoordinator,
        isPICProject,
    } = useAuth()
    
    const path = useLocation().pathname;

    const content = (
        <Box>
            <Flex
                align={'center'}
                h={"60px"}
                w={'full'}
                paddingX={"18px"}
            >
                <Image src={LogoWika} alt="Logo" height={'34px'} mr={'8px'}/>
                <Text fontSize='17px' color='#2D3748' as={'b'}>E Report System</Text>
            </Flex>
            <Box 
                h={'1px'}
                bgGradient="linear(to-r, #E0E1E2, #E0E1E2)"
                w={"88%"}
                marginX={"auto"}
            ></Box>
            <Box paddingX={"16px"} paddingY={"24px"}>
                <Stack spacing={"6px"}>
                    <DashSidebarLink path={path} title="Dashboard" route="dash" icon={MdOutlineDashboard}/>
                    {(isManager || isAdmin || isKoordinator || isPICProject) && (
                        <DashSidebarLink path={path} title="Projects" route="projects" icon={GoProject}/>
                    )}
                    {(isPICProject || isManager || isKoordinator || isAdmin) && (
                        <DashSidebarLink path={path} title="Task Koor" route="koor-assignment" icon={MdOutlineSpeakerNotes}/>
                    )}
                    {!isPICProject && (
                        <DashSidebarLink path={path} title="Task Engineering" route="tasks" icon={MdOutlineNotes}/>
                    )}
                    <DashSidebarLink path={path} title="Reports" route="reports" icon={HiOutlineDocumentReport}/>
                    {/* {(!isPICProject || !isManager) && (
                        <DashSidebarLink
                            path={path}
                            title="Utils"
                            route="utils"
                            icon={TbAbacus}
                            subItems={[
                                {route: 'utils/new', title: 'New Utils', icon: MdOutlineDashboard},
                            ]}
                        />
                    )} */}
                    {(isAdmin) && (
                        <DashSidebarLink path={path} title="Users" route="users" icon={BiUser}/>
                    )}
                </Stack>
            </Box>
        </Box>
    )
    return content
}
export default DashSidebar
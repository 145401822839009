import axios from "axios";

export const generateTaskNumber = (existingTaskNumber) => {
  return `WIKA-ENG-${existingTaskNumber + 1}-${new Date().getFullYear()}`;
};

export const generateTaskKoorNumber = (existingTaskNumber) => {
  return `WIKA-ENG-TK-${existingTaskNumber + 1}-${new Date().getFullYear()}`;
};

export const statusColor = (status) => {
  switch (status) {
    case "Open":
      return "#3182CE";
    case "Waiting Review":
      return "#319795";
    case "Revision I":
      return "#DD6B20";
    case "Revision II":
      return "#DD6B20";
    default:
      return "#38A169";
  }
}

export const uploadImage = async (image) => {
  const mainPath = process.env.REACT_APP_MS_WIKA_ERS_URL || "https://api-ers.infrasatu.com";

  const formData = new FormData();
  formData.append("image", image);

  try {
    const response = await axios.post(`${mainPath}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.fileUrl;
  } catch (error) {
    console.error(error);
  }
}

export const pathFile = (file) => {
  const url = process.env.REACT_APP_MS_WIKA_ERS_URL || 'https://api-ers.infrasatu.com'

  return `${url}/uploads/${file}`
}

export const editorModules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  },
  imageResize: {
    modules: ['Resize', 'DisplaySize', 'Toolbar'],
  }
}

export const editorFormats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote', 'align',
  'list', 'bullet', 'indent',
  'link', 'image', 'video', 'align'
]

export const converTime = (time, withTime) => {
  const date = new Date(time);
  let options = {};

  if (withTime) {
    options = {
      year: 'numeric', month: 'long', day: 'numeric',
      hour: 'numeric', minute: 'numeric'
    };
  } else {
    options = {
      year: 'numeric', month: 'long', day: 'numeric'
    };
  }

  let formattedDate = date.toLocaleDateString('id-ID', options);

  if (withTime) {
    formattedDate = formattedDate.replace('pukul', '-');
  }

  return formattedDate;
};

export const getPDFTasks = async (task) => {
  const mainPath = process.env.REACT_APP_MS_WIKA_ERS_URL || "https://api-ers.infrasatu.com";
  try {
    const response = await axios({
      url: mainPath + '/generate-pdf',
      method: 'POST',
      responseType: 'blob',
      data: {
        task
      }
    }).then((response) => {
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'}
      );
    
      const fileURL = URL.createObjectURL(file);
    
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `Report-${task?.taskNumber}-${task?.updatedAt}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
    
      URL.revokeObjectURL(fileURL);
      fileLink.remove();
    }).catch((error) => {
      console.error('Download error:', error);
    });

    return response;
  } catch (error) {
    console.error(error);
  }
}

export const getPPTTasks = async (task) => {
  const mainPath = process.env.REACT_APP_MS_WIKA_ERS_URL || "https://api-ers.infrasatu.com";
  try {
    const response = await axios({
      url: mainPath + '/generate-ppt',
      method: 'POST',
      responseType: 'blob',
      data: {
        task
      }
    }).then((response) => {
      const file = new Blob(
        [response.data], 
        {type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'}
      );
    
      const fileURL = URL.createObjectURL(file);
    
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'report.pptx');
      document.body.appendChild(fileLink);
      fileLink.click();
    
      URL.revokeObjectURL(fileURL);
      fileLink.remove();
    }).catch((error) => {
      console.error('Download error:', error);
    });

    return response;
  } catch (error) {
    console.error(error);
  }
}

export const sendWhatsappDirect = async (data) => {
  const mainPath = process.env.REACT_APP_MS_WIKA_ERS_URL || "https://api-ers.infrasatu.com";
  try {
    const response = await axios({
      url: mainPath + '/send-whatsapp',
      method: 'POST',
      data
    }).then((response) => {
      console.log(response.data)
    }).catch((error) => {
      console.error('Download error:', error);
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const formatPhoneNumber = (number) => {
  if (number.startsWith('0')) {
    return number.replace('0', '62')
  } else {
    return number
  }
}
